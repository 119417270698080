import React, { useEffect, useState } from 'react'
import { graphcms, QUERY_ALL_HOME} from '../Graphql/Queries'
import ReactLoading from 'react-loading';


function Home() {
  const [tip, setTip] = useState()
  const[bestSeller,setBestSeller] = useState()
  const [newDesign, setNewDesign] = useState()
  const[loading, setLoading] = useState(true);
  useEffect(()=>{

    graphcms.request(QUERY_ALL_HOME)
      .then(data => {
        setTip(data.designTips);
        setBestSeller(data.bestSellers);
        setNewDesign(data.newDesigns);
        setLoading(false);
      });
  }, [])

  if(loading){
    return(
      <div className='flex items-center justify-center h-screen text-secondary-100'>
        <ReactLoading type="spinningBubbles" color='rgb(228 157 91)' height={'200px'} width={'200px'} />
      </div>
    )
  }

  return (
    <div>
        <div className='sm:block md:flex max-w-[1320px] justify-between mx-auto my-0 flex-wrap'>
            <div className='md:w-3/4 p-5'>
              <div className='text-center bg-primary-100 text-secondary-100 p-4 rounded-2xl pb-6'>
                <div className='md:flex mx-auto my-0 items-center justify-center gap-6'>
                    <img className='my-0 mx-auto0 md:w-1/2' src={bestSeller[bestSeller.length-1].picture.url} alt="" />
              <p className='text-[23px] md:w-1/2'>{bestSeller[bestSeller.length-1].description}</p>
                </div>
              </div>
              <div className='text-center'>
                <h2 className='text-4xl text-secondary-100 my-4'>Tips</h2>
                      <div className='bg-secondary-100 p-4 rounded-lg'>
                          <p className='text-[23px]'>{tip[tip.length-1].tip}</p>
                </div>
              </div>
            </div>
            <div className='text-center bg-primary-100 text-secondary-100 p-4 rounded-lg md:w-[22%] object-contain'>
                  <h2 className='text-4xl text-secondary-100 my-4'>New Design</h2>

          <img className='my-0 mx-auto h-52' src={newDesign[newDesign.length - 1].picture.url} alt="" />
          <p className='text-[20px] mt-4'>{newDesign[newDesign.length - 1].description}</p>
          </div>
        </div>
        <div className='text-center '>
              <h2 className='text-4xl text-secondary-100 my-4'>Gallery</h2>
              <div className='bg-primary-100 p-4 rounded-lg'>
                  <div className='flex flex-wrap'>
                  <img className='pb-4 my-0 mx-auto md:h-52 sm:h-40' src="/assets/photo_2023-06-25_21-43-39.png" alt="" />
                  <img className='pb-4 my-0 mx-auto md:h-52 sm:h-40' src="/assets/photo_2023-06-25_21-43-39.png" alt="" />
                  <img className='pb-4 my-0 mx-auto md:h-52 sm:h-40' src="/assets/photo_2023-06-25_21-43-39.png" alt="" />
                  <img className='pb-4 my-0 mx-auto md:h-52 sm:h-40' src="/assets/photo_2023-06-25_21-43-39.png" alt="" />
                </div>
                  <a href="/product"><p className='text-white-100 bg-secondary-100 rounded-3xl text-xl inline-block px-16 py-1 mt-6'>Shop</p></a>
              </div>
        </div>
    </div>
  )
}

export default Home