import React from 'react'
import { FaFacebookF, FaInstagram, FaTwitter } from 'react-icons/fa'

function Footer() {
  const footerItems = [
    {
      id: 1,
      name: 'Home',
      link: '/',
    },
    {
      id: 2,
      name: 'About',
      link: '/about',
    },
    {
      id: 3,
      name: 'Product',
      link: '/product',
    },
    {
      id: 4,
      name: 'Events',
      link: '/events',
    },
    {
      id: 5,
      name: 'Impact',
      link: '/impact',
    },
    {
      id: 6,
      name: 'Contact',
      link: '/contact',
    }
  ];
  return (
    <div className='mt-9'>
      <div className='flex flex-col items-center justify-start w-full'>
        <div>
          <img className='h-44 w-auto' src="assets/logowc.png" alt="" />
          <p className='cursive text-3xl mx-auto md:mx-0 my-0 text-[#c98a4f] text-center font-[]'>Glittering The Rich Culture</p>

        </div>
        <h2 className='text-4xl text-secondary-100 my-4'>Links</h2>

        <div className='flex gap-6 bg-black-100 w-full p-8 mb-4 items-center flex-wrap justify-center'>
          {footerItems.map(item=>(
            <div>
              <a href={item.link} key={item.id} className={`cursor-pointer text-secondary-100 text-[24px] font-normal`}>
                {item.name}
              </a>
            </div>
          ))}
        </div>
              </div>
      <div className='flex flex-wrap justify-between max-w-[1300px] mx-auto my-0 px-3 text-center'>
          <p className='text-lg mx-auto md:mx-0 my-0 text-[#c98a4f] text-center'>Copyright &copy; 2023 Tubba Craft. All rights reserved</p>
          <div className='flex gap-6 mx-auto md:mx-0 my-0 mt-3 md:mt-0'>
          <a href=""><FaFacebookF /></a>
          <a href=""> <FaInstagram /></a>
          <a href=""> <FaTwitter /></a>
          </div>
    </div>
    </div>
  )
}

export default Footer