import React from 'react'

function ProductCard({ id, img, detail, code, price, size, stock, color, toggleDialog }) {
  return (
      <div className='md:w-72 sm:w-60'>
        <img className='w-full' src={img} alt="" />
        <div className=''>
              <p className='sm:text-[19px] md:text-[19px]  text-off-green-100'>Code: {code}</p>
              <p className='sm:text-[19px] md:text-[19px]  text-off-green-100'>Br. {price}</p>
              <p className='sm:text-[19px] md:text-[19px]  text-off-green-100'>Available Stock: {stock}</p>
        {color != "" ? <a href={`/product/${id}`} className='sm:text-[19px] md:text-[19px]  text-blue-500 font-medium cursor-pointer'>Color Varient</a> : ''}
        <p className='sm:text-lg md:text-[19px]  text-off-green-100'>Size: {size}</p>
        {detail ? <p className='sm:text-[19px] md:text-[19px]   text-off-green-100'>Detail: {detail}</p> : ''}

        </div>
    </div>
  )
}

export default ProductCard