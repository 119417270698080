import React, { useEffect, useState } from 'react'
import { QUERY_ALL_PRODUCT, graphcms } from '../Graphql/Queries';

function Filter() {
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState();
    const [filteredProduct, setfilteredProduct] = useState();
    const [category, setCategory] = useState();
    const [color, setColor] = useState();
    const [search, setSearch] = useState();
    const [selectedOptionCategory, setSelectedOptionCategory] = useState('');
    const [selectedOptionColor, setSelectedOptionColor] = useState('');
    const [dialog, setDialog] = useState(false);
    const [imageSrc, setImageSrc] = useState(
        "https://www.sarkarinaukriexams.com/images/post/1670771584desola-lanre-ologun-IgUR1iX0mqM-unsplash_(1).jpg"
    );
    const [currColor, setCurrColor] = useState();
    const [count, setCount] = useState(0);
    const [postPerPage, setPostPerPage] = useState(9);
    const [currPage, setCurrPage] = useState(1);
    const [title, setTitle] = useState("Product")
    const [detail, setDetail] = useState()

    useEffect(() => {
        console.log("category ", selectedOptionCategory)

        setfilteredProduct(product)
        category?.map(item => item.name).includes(selectedOptionCategory)
        setfilteredProduct(product?.filter((item) => {
            return item.category.name == selectedOptionCategory
        }))
        console.log(filteredProduct)

    }, [selectedOptionCategory]);

    // useEffect(() => {

    // }, [selectedOptionColor]);

    const handleOptionChange = (e) => {
        if (selectedOptionCategory == "") {
            alert("Please choose product type")

        } else {
            console.log(e.target.value)
            setSelectedOptionColor(e.target.value);
            // color.map(item => item.name).includes(selectedOptionColor)
            setfilteredProduct(filteredProduct.filter((item) => {

                // Check if any color name matches 
                return item.productColors.some(color => {
                    return color.colores.name === e.target.value;
                });

            }));
            // console.log(selectedOption)
        }
    }

    const handleCategory = (e) => {
        setTitle(e.target.textContent);
        setSelectedOptionCategory(e.target.textContent)
        setfilteredProduct(product.filter((item) => {
            return item.category.name == e.target.textContent
        }))
        // console.log(filteredProduct)
    }
    useEffect(() => {
        graphcms.request(QUERY_ALL_PRODUCT)
            .then(data => {
                setProduct(data.products)
                setfilteredProduct(data.products)
                setCategory(data.categories.sort((a, b) => {
                    if (a.name < b.name) return -1;
                    if (a.name > b.name) return 1;
                    return 0;
                }))
                setColor(data.colors)
                // setEvents(data.events);
                setLoading(false);
            }).catch(err => console.log(err));
    }, [])

    const handleRefresh = () => {
        setfilteredProduct(product);
        setSelectedOptionColor("");
        setSelectedOptionCategory("");
        setTitle("Product");
    }

    const catagoryList = category?.map(item => item.name)

  return (
      <div className='md:sticky top-3 p-10 px-20 mx-8 rounded-xl bg-primary-100 text-secondary-100 h-fit felx flex-col justify-center'>
          <h2 className='text-3xl text-secondary-100 mt-[-20px] text-center'>Filter</h2>

          <div className='flex items-center gap-3 md:justify-between justify-center'>

              <select className='bg-[#38383A] mt-5 py-2 text-[19px] font-semibold px-2 rounded-md border-[#A1A1A1]' value={selectedOptionCategory} onChange={(e) => { setSelectedOptionCategory(e.target.value); setTitle(e.target.value); }}>
                  <option value="">Type</option>
                  {category?.map(i => (
                      <a href="/product"> <option className={`${title == i.name ? 'text-white-100' : ''}`} onClick={handleCategory} key={i.id} value={i.name}>{i.name}</option></a>
                  ))}
              </select>
          </div>
          <select className='bg-[#38383A] mt-3 mb-1 py-2 text-[19px] font-semibold px-2 rounded-md border-[#A1A1A1]' value={selectedOptionColor} onChange={handleOptionChange}>
              <option value="">Color</option>
              {color?.map(i => (
                 <a href="/product"><option key={i.id} value={i.name}>{i.name}</option></a>
              ))}
          </select>
          <h2 className='text-secondary-100 text-xl cursor-pointer bg-[#38383A] py-2 px-4 rounded-md mt-5 mb-4' onClick={handleRefresh}>Refresh Filter</h2>

          <div className='overflow-y-scroll h-[400px]'>
              {catagoryList?.map(item => {
                  return (<div>
                      <a href="/product"><h2 className={`${title == item ? 'text-white-100' : ''} cursor-pointer text-xl text-secondary-100 my-4`} onClick={handleCategory}>{item}</h2></a>
                  </div>)
              })}
          </div>

      </div>
  )
}

export default Filter