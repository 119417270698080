import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { QUERY_ALL_PRODUCT, graphcms } from '../Graphql/Queries';
import ReactLoading from 'react-loading';
import Filter from '../components/Filter';

function ColorVariation() {
    const { id } = useParams();
    const [product, setProduct] = useState();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        graphcms.request(QUERY_ALL_PRODUCT)
            .then(data => {
                setProduct(data.products.filter(i => i.id == id))
                console.log(data.products.filter(i => i.id == id))
                setLoading(false);
            }).catch(err => console.log(err));
    }, [])

    if (loading) {
        return (
            <div className='flex items-center justify-center h-screen text-secondary-100'>
                <ReactLoading type="spinningBubbles" color='rgb(228 157 91)' height={'200px'} width={'200px'} />
            </div>)
    }

  return (
    <div className='min-h-full md:flex max-w-[1420px] my-0 mx-auto p-5 '>
        <Filter />
        <div className='w-full'>
              <div className='md:w-[600px] md:flex-row items-center flex-col flex  gap-10'>
                  <img className='w-3/4' src={product[0].picture.url} alt="" />
                  <div className='w-1/2 text-[25px] '>
                      <p className=' text-off-green-100'>Code: {product[0].code}</p>
                      <p className='  text-off-green-100'>Br. {product[0].price}</p>
                      <p className=' text-off-green-100'>Available Stock: {product[0].stock}</p>
                      <p className=' text-off-green-100'>Size: {product[0].size}</p>
                  </div>
              </div>
              <div className='mt-32'>
                  <h2 className='text-secondary-100 text-center text-[35px]'>Color Variations</h2>
                  <div className='w-full flex flex-wrap gap-14'>
                      {product[0].productColors.map(p=>(
                          <div className='md:w-[300px] mt-8'>
                              <img className='w-full' src={p.colorPicture.url} alt="" />
                              <h2 className='text-secondary-100 text-center text-[25px]'>{p.codeName}</h2>
                        </div>
                      ))}
                  </div>
              </div>
        </div>
    </div>
  )
}

export default ColorVariation