import React from 'react'

function Impact() {
  return (
    <div className='max-w-[1220px] my-0 mx-auto p-5'>
      <div className='sm:block md:flex flex-wrap'>
        <div className='md:w-1/2'>
          <h2 className='sm:text-center md:text-left text-3xl text-secondary-100 my-4'>Social Impact</h2>
          <p className='text-xl md:text-[22px] mt-4 text-off-green-100'>Job is not only a source of income and enjoyment, but also major component of self- dignity. Tubba Crafts provides a secure stable employment opportunity for those who need it. Thus far the organization has created job opportunities for 14 permanent employees. Tubba also offered gratis training opportunities for four housewives with young children at company's expense. Currently, these Tubba family members have secured permanent employment with the training they have received and are self- supporting their livelihood. Tubba Crafts also supports country's tourism sector by sourcing quality products which glitter the rich culture.</p>
        </div>
        <div className='md:w-1/2 bg-primary-100 object-cover pr-5'>
          <h2 className=' text-2xl text-secondary-100 my-4 text-center'>Hands behind our crafts</h2>
          <div className='flex w-full gap-2'>
            <img className='w-1/3 h-auto' src="/assets/social-impact1.jpg" alt="" />
            <img className='w-1/3 h-auto' src="/assets/social-impact2.jpg" alt="" />
            <img className='w-1/3 h-auto' src="/assets/social-impact3.jpg" alt="" />
          </div>
          

        </div>
      </div>
      <div className='md:flex  mt-12 justify-between'>
        <div className='md:w-1/3 bg-primary-100 flex justify-center items-center'>
          <img className='w-[90%] h-auto ' src="/assets/enviromental-impact1.jpg" alt="" />
        </div>
      <div className='md:w-[60%]'>
          <h2 className='sm:text-center md:text-left text-3xl text-secondary-100 my-4'>Environmental Impact</h2>
          <p className='text-xl md:text-[22px] mt-4 text-off-green-100'>
            our production process is near zero carbon emission and low energy consumption as a result of the use of simple mechanical tools at all steps of production. <br />
            -Emphasis on hand made products. <br />
            -Negligible electricity usage <br />
            -The use of natural raw materials to make our tools and manufacture our products <br />
            -significantly minimizes the amount of toxins release into the environment at all levels of the supply chain.

          </p>
      </div>
      </div>
    </div>
  )
}

export default Impact