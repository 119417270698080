import React, { useEffect, useState } from 'react'
import EventsCard from '../components/EventsCard'
import { graphcms, QUERY_ALL_Event} from '../Graphql/Queries'
import ReactLoading from 'react-loading';


function Events() {
  const [events, setEvents] = useState()
  const [loading, setLoading] = useState(true);
  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const month = monthNames[dateObj.getMonth()];
    const day = dateObj.getDate();
    const year = dateObj.getFullYear();
    const formattedDate = `${month} ${day}, ${year}`;
    return formattedDate;
  }
  useEffect(() => {

    graphcms.request(QUERY_ALL_Event)
      .then(data => {
        console.log(data,events)
        setEvents(data.events);
        setLoading(false);
      });
  }, [])
  if(loading){
    return(
    <div className='flex items-center justify-center h-screen text-secondary-100'>
      <ReactLoading type="spinningBubbles" color='rgb(228 157 91)' height={'200px'} width={'200px'} />
    </div>)
  }
  return (
    <div className='max-w-[1320px] my-0 flex mx-5 flex-wrap sm:justify-center md:justify-between'>
        <div className='sm:w-full md:w-[53%] md:ml-16'>
          {events?.map(event=>(
            <EventsCard title={event.title} description={event.description} image={event.coverPicture.url} date={formatDate(event.publishedAt)} author={event.publishedBy.name} />

          ))}
        </div>
        <div className='bg-primary-100 h-fit mt-6 rounded-xl p-8 px-12 mx-auto my-0'>
              <h2 className='text-3xl text-white-100 mb-4 border-b-white-100 text-center'>Recent Events</h2>
              <hr className='bg-[#C3C3C3] text-center h-[2px] mt-[-5px] w-[95%] mx-auto my-0'/>
            {
          events?.slice(-3).map(event=>(
              
                <div className='flex gap-4 items-center text-white-100 my-5'>
                  <div>
                <p className=' text-sm '>{formatDate(event.publishedAt)}</p>
                <p className=' text-lg '>{event.title}</p>
                  </div>
                </div>
              ))
            }
              
        </div>
    </div>
  )
}

export default Events