import React from 'react'
import { BsFillPersonFill } from "react-icons/bs";
import { VscCalendar } from "react-icons/vsc";


function EventsCard({ title, image, description, date, author }) {
  return (
      <div className='w-full  bg-primary-100 text-white-100 text-center py-6 px-4 rounded-xl my-8'>
          <div className='mt-2 flex flex-col justify-around gap-5'>

              <img className=' w-full h-[200px] object-cover rounded-xl' src={image} />
              <h1 className='text-2xl font-bold'>{title}</h1>
              <div className='flex justify-center gap-9'>
                  <p className=' text-md flex items-center gap-2'><span className='text-3xl'><BsFillPersonFill /></span>{author}</p>
                  <p className=' text-md flex items-center gap-2'><span className='text-3xl'><VscCalendar /></span>{date}</p>
              </div>
              <p className='text-xl font-medium mt-2'>{description}</p>
              
          </div>
      </div>
  )
}

export default EventsCard