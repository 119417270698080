import { GraphQLClient, gql } from "graphql-request";

export const graphcms = new GraphQLClient('https://api-eu-west-2.hygraph.com/v2/cljnx8uqn104e01unhwo30oxv/master')
    // graphcms.setHeaders({
    //     authorization: `Bearer ${process.env.REACT_APP_TOKEN}`,
    // });

const category = `
     id,
    name,
    products {code}
`
const bestSeller = `
description,
    picture {
      url
    },
    id
`
const designTip = `
    tip
`
const event = `
    id,
    publishedAt,
    publishedBy {
      name
    },
    coverPicture {
      url
    },
    description,
    title
`

const newDesign = `
    id,
    description,
    picture {
      url}
`
const productColor = `
 color,
    id
`
const product = `
    code,
    price,
    category {
      name
    },
    productColors {
      codeName
    },
    size,
    stock,
    picture {
      url
    }
`

export const QUERY_DESIGNTIP = gql `
{
    designTips(){
        tip
    }
}
`
export const QUERY_BESTSELLER = gql `
{
    bestSellers(){
        description,
    picture {
      url
    }
    }
}
`

export const QUERY_NEWDESIGN = gql `
{
    newDesigns(){
        description,
        picture {url}
    }
}
`
export const QUERY_ALL_HOME = gql `
    query {
        newDesigns{
        description,
        picture {url}
    }

    bestSellers{
        description,
    picture {
      url
    }
    }

    designTips{
        tip
    }
    }
`

export const QUERY_ALL_Event = gql `
    query {
        events (first: 500){
             id,
    publishedAt,
    publishedBy {
      name
    },
    coverPicture {
      url
    },
    description,
    title
        }
    }
`

export const QUERY_ALL_PRODUCT = gql `
    query {
        products (first: 500){
            id,
            code,
            price,
            category {
            name
            },
            productColors {
            codeName,
                   colores {
      name
    },
            colorPicture {url}
            },
            size,
            stock,
            picture {url},
            detail
        }

        productColors (first: 500){
            codeName,
             colores {
      name
    },
            colorPicture {
        url
      },
            id
        }

        categories (first: 500){
            id,
            name,
            products {code},
        }

        colors {
    name
    id
  }
    }
`