import React from 'react'

function About() {
  return (
      <div className='max-w-[1220px] my-0 mx-auto p-5'>
        <div className='mb-[65px]'>
              <h1 className='sm:text-center md:text-left text-3xl text-secondary-100 my-4'>About Tubba Craft</h1>
        <p className='text-[20px] md:text-[23px] mt-4 text-off-green-100'>Tubba Crafts is established in 2019 G.C with the aim of creating profitable organization which produces high end quality handcrafted fabrics which are competent in the international market while fulfilling customer demand on high quality.
<br /><br />
          The Company is engaged mainly in designing and production of quality handcrafted soft furnishings and garments by using local raw materials. The products come with distinctive designs that reflect belief, culture, creativity and skill of the society.
<br /><br />
          The Company plays it's role in socio economic development of the country by creating additional job opportunities, foreign exchange generation, culture conservation and environment preservation.
</p>
        </div>
        <div className='flex md:justify-between sm:justify-center flex-wrap md:mb-24 sm:mb-10'>
            <div className='flex items-center mb-10'>
                <img className='h-fit mr-3' src="/assets/nature-of-bussiness.png" alt="" />
                <div>
                      <p className='text-[20px] md:text-[22px]  text-secondary-100'>Nature of Business</p>
                      <p className='text-lg md:text-[20px] text-off-green-100'>Manufacturer</p>
                </div>
            </div>
              <div className='flex items-center mb-10'>
                <img className='h-fit mr-3' src="/assets/year-of-establishment.png" alt="" />
                  <div>
                      <p className='text-[20px] md:text-[22px]  text-secondary-100'>Year of Establishment</p>
                      <p className='text-lg md:text-[20px] text-off-green-100'>2019</p>
                  </div>
              </div>
              <div className='flex items-center mb-10'>
                <img className='h-fit mr-3' src="/assets/number-of-employees.png" alt="" />
                  <div>
                      <p className='text-[20px] md:text-[22px]  text-secondary-100'>Number of Employees</p>
                      <p className='text-lg md:text-[20px] text-off-green-100'>14+</p>
                  </div>
              </div>
              <div className='flex items-center mb-10'>
          <img className='h-fit mr-3' src="/assets/values.png" alt="" />

                  <div>
                      <p className='text-[20px] md:text-[22px]  text-secondary-100'>Legal Status</p>
                      <p className='text-lg md:text-[20px] text-off-green-100'>Sole proprietorship </p>
                  </div>
              </div>
             
        </div>
        <div className='flex sm:justify-center md:justify-between flex-wrap items-start'>
          <div className='flex items-center mb-10'>
          <img className='h-fit mr-3' src="/assets/legal-stat.png" alt="" />

              <div>
                  <p className='sm:text-lg md:text-[20px]  text-secondary-100'>Values</p>
                      <p className='sm:text-lg md:text-[20px] text-off-green-100'>Quality, Comfort, Beauty,<br />
                      Customer Satisfaction,<br />
                      +ve social & Environmental Impact</p>
              </div>
          </div>
              <img src="/assets/about-bottom-image.png" alt="" />
        </div>
    </div>
  )
}

export default About