import React, { useEffect, useState } from 'react'
import ProductCard from '../components/ProductCard'
import { FaSearch } from "react-icons/fa";
import { GrPowerReset, GrRefresh, Grleft } from "react-icons/gr"
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md"
import { QUERY_ALL_PRODUCT, graphcms } from '../Graphql/Queries';
import ReactLoading from 'react-loading';
import Pagination from '../components/Pagination';



function Product() {
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState();
  const [filteredProduct, setfilteredProduct] = useState();
  const [category, setCategory] = useState();
  const [color, setColor] = useState();
  const [search, setSearch] = useState();
  const [selectedOptionCategory, setSelectedOptionCategory] = useState('');
  const [selectedOptionColor, setSelectedOptionColor] = useState('');
  const [dialog, setDialog] = useState(false);
  const [imageSrc, setImageSrc] = useState(
    "https://www.sarkarinaukriexams.com/images/post/1670771584desola-lanre-ologun-IgUR1iX0mqM-unsplash_(1).jpg"
  );
  const [currColor, setCurrColor] = useState();
  const [count, setCount] = useState(0);
  const [postPerPage, setPostPerPage] = useState(9);
  const [currPage, setCurrPage] = useState(1);
  const [title, setTitle] = useState("Product")
  const [detail, setDetail] = useState()

  const toggleDialog = (color) => {
    setDialog(true);
    setCurrColor(color);
    // if (currColor) { console.log(currColor.map(c => (c.color)).join(", "))}
  };


  useEffect(() => {
    graphcms.request(QUERY_ALL_PRODUCT)
      .then(data => {
        setProduct(data.products)
        setfilteredProduct(data.products)
        setCategory(data.categories.sort((a, b) => {
          if (a.name < b.name) return -1;
          if (a.name > b.name) return 1;
          return 0;
        }))
        setColor(data.colors)
        // setEvents(data.events);
        setLoading(false);
      }).catch(err=>console.log(err));
  }, [])

  const handleSearchChange=(e)=>{
    // e.preventDefault();
    // setSearch(()=>{e.target.value})
    setSearch(e.target.value) 
    // console.log(search);
  }

  const handleSearchSubmit=(e)=>{
    e.preventDefault();
    // console.log(search);
    const products = product
    setfilteredProduct(product.filter((item)=>{
      return (item.category.name).toLowerCase().includes(search.toLowerCase()) 
    }))
    // console.log(product)
    // console.log(filteredProduct)
    
  }
  useEffect(() => {
    console.log("category ", selectedOptionCategory)
    if (selectedOptionCategory == ""){
      setfilteredProduct(product)}else{
    category?.map(item => item.name).includes(selectedOptionCategory)
      setfilteredProduct(product?.filter((item) => {
        return item.category.name == selectedOptionCategory
      }))
    console.log(filteredProduct)
    }
  }, [selectedOptionCategory]);

  // useEffect(() => {
   
  // }, [selectedOptionColor]);

  const handleOptionChange = (e)=>{
    if (selectedOptionCategory == "") {
      alert("Please choose product type")

    }else{
      console.log(e.target.value)
   setSelectedOptionColor(e.target.value);
      // color.map(item => item.name).includes(selectedOptionColor)
      setfilteredProduct(filteredProduct.filter((item) => {

  // Check if any color name matches 
  return item.productColors.some(color => {
    return color.colores.name === e.target.value; 
  });

}));
    // console.log(selectedOption)
    }
  }

  const handleCategory = (e)=>{
    setTitle(e.target.textContent);
    setSelectedOptionCategory(e.target.textContent)
    setfilteredProduct(product.filter((item) => {
    return item.category.name == e.target.textContent
    }))
    // console.log(filteredProduct)
  }
                 
  
  const handleRefresh = () => {
   setfilteredProduct(product); 
    setSelectedOptionColor("");
    setSelectedOptionCategory("");
    setTitle("Product");
  }
  if (loading) {
    return (
      <div className='flex items-center justify-center h-screen text-secondary-100'>
        <ReactLoading type="spinningBubbles" color='rgb(228 157 91)' height={'200px'} width={'200px'} />
      </div>)
  }

    const catagoryList = category.map(item=>item.name)
  // console.log(color)

  const lastPostIndex = currPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  const currentPost = filteredProduct.slice(firstPostIndex,lastPostIndex)

  

  return (
    <div className='min-h-full md:flex max-w-[1420px] p-5 '>
        <div className='md:sticky top-3 p-10 px-20 mx-8 rounded-xl bg-primary-100 text-secondary-100 h-fit felx flex-col justify-center'>
              <h2 className='text-3xl text-secondary-100 mt-[-20px] text-center'>Filter</h2>
              
        <div className='flex items-center gap-3 md:justify-between justify-center'>
    
          <select className='bg-[#38383A] mt-5 py-2 text-[19px] font-semibold px-2 rounded-md border-[#A1A1A1]' value={selectedOptionCategory} onChange={(e) => { setSelectedOptionCategory(e.target.value); setTitle(e.target.value); }}>
            <option value="">Type</option>
            {category.map(i => (
              <option className={`${title == i.name ? 'text-white-100' : ''}`} onClick={handleCategory} key={i.id} value={i.name}>{i.name}</option>
            ))}
          </select>
        </div>
        <select className='bg-[#38383A] mt-3 mb-1 py-2 text-[19px] font-semibold px-2 rounded-md border-[#A1A1A1]' value={selectedOptionColor} onChange={handleOptionChange}>
          <option value="">Color</option>
          {color.map(i=>(
            <option key={i.id} value={i.name}>{i.name}</option>
          ))}
        </select>
        <h2 className='text-secondary-100 text-xl cursor-pointer bg-[#38383A] py-2 px-4 rounded-md mt-5 mb-4' onClick={handleRefresh}>Refresh Filter</h2>

        <div className='overflow-y-scroll h-[400px]'>
              {catagoryList.map(item=>{
            return (<div>
                      <h2 className={`${title == item ? 'text-white-100' : ''} cursor-pointer text-xl text-secondary-100 my-4`}  onClick={handleCategory}>{item}</h2>
                    </div>)})}
        </div>
       
        </div>
        <div className='flex flex-col'>
          
        <h2 className='text-4xl text-secondary-100 mb-6 w-full'>{title}</h2>
        {dialog && (
          <div className='fixed inset-0 flex items-center justify-center'>
          <div className="dialog absolute  z-10 px-5 md:px-0  h-[60%] ">
            <div className='relative top-1/2 md:top-[10%]'>
            {currColor.length>1?(<div className='flex justify-between h-full absolute w-full items-center text-white-100 top-8 left-0'>
                <MdKeyboardArrowLeft className='text-4xl cursor-pointer' onClick={() => setCount(count == 0 ? currColor.length - 1 : count - 1)} />
                <MdKeyboardArrowRight className='text-4xl cursor-pointer' onClick={() => setCount(count == currColor.length - 1 ? 0 : count + 1)} />
            </div>):""}
            <div className="dialog-content bg-secondary-100">
              <div className='flex w-11/12 mx-auto my-0 justify-between'>

                <button onClick={() => {setDialog(false);}} className="float-left close-icon text-3xl">&times;</button>
                <h2 className='text-3xl text-black-100 text-center'>{currColor[count].color}</h2>
              </div>
                <img className='w-[500px] h-auto' src={currColor[count].colorPicture.url} alt="Popup Image" />
            </div>
            </div>
          </div>
          </div>
        )}
           <div className='flex flex-wrap gap-5 md:justify-between justify-center'>
          {filteredProduct.length == 0 ? "No product found" : currentPost.map(i=>(
            <ProductCard detail={i.detail} toggleDialog={toggleDialog} id={i.id} img={i.picture.url} code={i.code} price={i.price} size={i.size} stock={i.stock} color={i.productColors}/>
            ))}
         
           </div>
        <Pagination totalPosts={filteredProduct.length} postPerPage={postPerPage} setCurrentPage={setCurrPage} currentPage={currPage}/>

        </div>
    </div>
  )
}

export default Product  