import Home from './pages/Home';
import Impact from './pages/Impact';
import About from './pages/About';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Product from './pages/Product';
import Contact from './pages/Contact';
import Events from './pages/Events';
import Footer from './components/Footer';
import Header from './components/Header';
import ColorVariation from './pages/ColorVariation';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/impact' element={<Impact />} />
          <Route path='/about' element={<About />} />
          <Route path='/product' element={<Product />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/events' element={<Events />} />
          <Route path="/product/:id" element={<ColorVariation/>} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
